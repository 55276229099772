.btn-orange{
    font-size: 10px;
   color: white;
   background-color:orange;
   width: 110px;
   border-radius: 5px;
   height: 25px;
   font-weight: 500;
/* text-align: left; */
}

.btn-disabled {
    background-color:rgb(238, 213, 167); /* Light gray */
    color: black; /* Darker gray */
    cursor: not-allowed;
    font-size: 10px;
    width: 110px;
    border-radius: 5px;
    height: 25px;
    font-weight: 500;


  }
  
  .btn-disabled:hover {
    background-color: rgb(238, 213, 167); /* Slightly darker gray for hover */
  }
  
  .slot-indicator-green {
    background-color: #22c55e; /* Green */
    color: white;
  }
  
  .slot-indicator-red {
    background-color: #ef4444; /* Red */
    color: white;
  }
  