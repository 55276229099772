.services-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin: 16px;
    width: 300px;
    margin-top:50px;
    /* height:54vh; */
    min-height: 400px;
    max-height: 400px;
    overflow: hidden;
  }
  
  .service-image {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .services-row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .business-section {
    margin-bottom: 40px; /* Space between different businesses */
  }
  
  .business-info {
    margin-top: 16px;
  }
  /* .book-service-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  } */

  .service-information{
    display:flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
  }
  .ser-btn{
    width: 150px;
  }
  .service-heading{
    color:#273B60;
    


  }

  .service-text{
    font-size: 15px;
    color:#273B60;
  }