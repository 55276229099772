.flex1{
    display: flex;
    justify-content: center;
    align-items: center;
}
h2.succ{
    color: white;

}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}