/* style.css */
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}
/* Modal overlay to cover the entire screen */
.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Slightly dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it sits above everything else */
  }
  
  /* Modal content styles */
  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    max-width: 100%;
    width: 1000px;
    height: 80%; /* Increased height */
    overflow-y: auto; /* Scroll if content overflows */
    position: relative;
  }
  /* Close button styling */
.modal-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    transition: color 0.2s ease-in-out;
  }
  
  .modal-close-btn:hover {
    color: #ff0000; /* Optional: Red on hover for better UX */
  }
  
  .prod-wrapper {
    position: relative;
    border: 1px solid #dedede;
    padding: 15px;
    transition: transform 0.3s ease;
  }
  
  .prod-wrapper:hover {
    transform: scale(1.05);
  }
  
  .img img {
    width: 150%;
    object-fit: contain;
  }
  
  .prod-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .prod-info button {
    position: absolute;
    top: 10px; /* Adjust this based on the positioning */
    right: 10px;
    background-color: white;
    padding: 5px;
    border-radius: 50%;
  }
  
  .prod-info .line-through {
    font-size: 0.85rem;
    color: #999;
  }
  
  .prod-info .bg-orange-500 {
    background-color: #F16427; /* Same color as the image's add button */
    border-radius: 20px;
    padding: 4px 10px;
  }
  

  .add-cart-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f5f5f5; /* Light background to match the container */
    border-radius: 20px; /* Rounded corners */
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border: 1px solid #dedede;
  }
  
  .add-cart-btn span {
    font-size: 25px;
    color: #F16427; /* Orange text color */
  }
  
  .add-cart-btn:hover {
    background-color: #ececec; /* Slight background change on hover */
    cursor: pointer;
  }
 .main-prod-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 200vh; */
  margin-bottom: 100px;
  min-height: 300px; /* Set a minimum height when empty */
  max-height: auto;
  margin-top: 20px;
  flex: 1;
 }


 /* toggle btn styles  */
 /* Container styles */
 .toggle-button-container {
  position: absolute;
  top: 8rem;
  right: 1rem;
  /* z-index: 1; */
   /* Lower the z-index to stay behind other elements */
  margin-bottom: 20px;
}

.toggle-button {
  display: flex;
  background-color: white;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  
}

.toggle-option {
  flex: 1;
  padding: 0.5rem 1.5rem;
  border: none;
  background: none;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-option.active {
  background-color: #FF4D00;
  color: #fff;
  border-radius: 30px;
}

.toggle-option:not(.active):hover {
  background-color: rgb(221, 215, 215);
}
