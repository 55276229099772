.header {
    font-family: Arial, sans-serif;
    width: 100%;
  }
  
  /* Top Sale Announcement Bar */
  .header-top {
    background-color: #FF4D00;
    color: white;
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
  }
  
  /* Main Header */
  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #273B60;
    padding: 10px 20px;
  }
  
  .logo {
    /* height: 50px; */
  }
  
  .location {
    display: flex;
    align-items: center;
    color: white;
    font-size: 14px;
    font-weight: normal;
    /* margin-left: 20px; */
  }
  .val{
    display: flex;
    flex-direction: column;
  }
  .location-icon {
    margin-right: 5px;
  }
  
  .location-value {
    /* font-weight: bold; */
  }
  
  .departments-button {
    background-color: #273B60;
    color: white;
    border: none;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dropdown-icon {
    margin-left: 5px;
  }
  
  .searching-bar {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 25px;
padding: 8px 10px;
width: 500px;
height: 50px;
  }
  
  .searching-icon {
    margin-right: 8px; /* Add space between icon and input */
    color: #888; /* Optional: Adjust icon color */
  }
  
  .searching-input {
    border: none;
    outline: none;
    flex: 1;
    padding-left: 5px; /* Extra space inside input */
  }
  
  
  .icons {
    display: flex;
    gap: 60px;
  }
  
  .icon-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 12px;
  }
  .en-icon-group{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 12px;
    margin-top: 20px;
  }
  
  .custom-icon {
    /* width: 24px;
    height: 24px; */
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  .icon-label {
    margin-top: 10px;
  }
  .ic-label {
    font-weight: bold;
    /* margin-top: 55px; */
    /* padding-top: 100px; */
  }
  
  
  /* Bottom Club and Menu Links */
  .header-bottom {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    padding: 5px 20px;
    border-bottom: 1px solid #D5DEE4;
  }
  
  .club {
    color:black;
    font-size: 14px;
  }
  
  .club-location {
    color:#0067A0;
  
    font-weight: bold;
  }
  
  .menu {
    display: flex;
    gap: 15px;
    margin-left: 900px;
  }
  
  .menu-link {
    color: black;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .menu-link:hover {
    text-decoration: underline;
  }
  
  .vertical-line {
    width: 1px;
    height: 40px; /* Adjust height as needed */
    background-color: #ccc; /* Set to the line color */
    margin: 0 10px; /* Space between icon groups */
    align-self: center;
  }
  .ics{
    display: flex;
    /* width: 50px; */
  }

  
  .departments {
    position: relative;
    display: inline-block;
  }
  
  /* .departments-button {
    background: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
  } */
  
  .dropdown-icon {
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 110%;
    left: 0;
    display: flex;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 800px; /* Adjust width as needed */
    display: none;
    font-family: Montserrat;
  }
  
  .departments:hover .dropdown-menu {
    display: flex;
  }
  
  .dropdown-section {
    flex: 1;
    margin-right: 16px;
  }
  
  .dropdown-section:last-child {
    margin-right: 0;
  }
  
  .section-icon {
    width: 32px; /* Adjust size as needed */
    height: 32px;
    margin-bottom: 8px;
  }
  
  h4, h5 {
    color: #333;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  h5 {
    font-size: 0.9em;
    color: #555;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin-bottom: 4px;
    /* color: #555; */
    cursor: pointer;
  }
  
  li:hover {
    text-decoration: underline;
  }
  
  /* en drop down  */
  .en-icon-group {
    position: relative;
  }
  
  .language-dropdown {
    position: absolute;
    top: 100%; /* Position below the icon */
    left: 0;
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 15px;
    width: 150px; /* Adjust as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    font-size: 14px;
    /* height: 400px; */
    color: #333;
  }
  
  .language-dropdown h4 {
    font-size: 14px;
    margin: 5px 0;
    font-weight: bold;
  }
  
  .language-dropdown a {
    color: #0073e6;
    text-decoration: none;
    font-size: 12px;
  }
  
  .language-dropdown form label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    color: #333;
  }
  
  .language-dropdown form input {
    margin-right: 8px;
  }
  
  .language-dropdown hr {
    margin: 10px 0;
    border: 0;
    height: 1px;
    background-color: #ddd;
  }
  
  .language-dropdown p {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .language-dropdown p a {
    color: #0073e6;
    text-decoration: none;
    font-size: 12px;
  }
  .inp{
    width: 10%;
  }

   
  .menu {
    display: flex;
    gap: 20px;
    font-family: Arial, sans-serif;
}

.menu-link {
    text-decoration: none;
    color: black;
    position: relative;
    /* padding: 10px; */
}

.more {
    position: relative;
}

.submenu {
    display: none;
    position: absolute;
    top: 100%;
    left:-45px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    z-index: 1;
    margin-right: 100px;
}

/* more drop down  */

.more:hover .submenu {
    display: block;
    
}

.submenu-link {
    display: block;
    padding: 10px 20px;
    color: black;
    text-decoration: none;
    white-space: nowrap;
}

.submenu-link:hover {
    background-color: #f0f0f0;
}
/* sign in drop down  */
.dropdown-content {
    display: none; /* Initially hidden */
    position: absolute;
    top: 100%;
    left: -180px;
/* margin-right: 2000px; */
    width: 320px;
    background-color: rgb(255, 254, 254);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 5px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    height: 300px;
    font-family: Montserrat;
}

.left-section, .right-section {
    padding: 10px;
}

.left-section {
    width: 60%;
    /* border-right: 1px solid #ccc; */
}

.right-section {
    width: 40%;
    padding-left: 20px;
}

.sign-in {
    background-color: #37475a;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 10px;
    border-radius: 5px;
    cursor: pointer;
    /* margin-bottom: 10px; */
}
.member{
    font-size: 14px;
}
.menu-item {
    display: block;
    color: #333;
    /* padding: 5px 0; */
    text-decoration: none;
}

.menu-item:hover {
    color: #0073bb;
}

h3 {
    font-size: 10px;
    margin-bottom: 10px;
}

/* .icon-group:hover .dropdown-content {
    display: block;
} */
.list{
    color: black;
    font-size: 15px;
    /* font-weight: 400; */
    /* font-style: bolder; */
}
.unlogged-section{
    width: 100%;
    height: 200px;
}
.unmenu-item{
   
        display: block;
        color: #333;
        /* padding: 5px 0; */
        text-decoration: none;
  font-size: 15px;
}
.itemsna{
    font-size: 10px;
    margin-bottom: 0px;
}



@media (max-width: 768px) {
  
  .header-main{
    height: 75px;
    padding: 5px;
  }
  .image-logo{
    height: 35px;
  }
  .icons{
    gap: 10px;
  }
  .icon-group{
    font-size: 8px;
  }
  .custom-icon{
    height: 50%;
  }
  .en-icon-group img{
    height: 50%;
  }
  .searching-bar{
    width: 345px;
    height:40px;
  }
  .search-button span {
   font-size: 15px;
  }
}


@media (max-width: 425px) {
  .header-main{
    height: 65px;
    padding: 5px;
  }
  .image-logo{
    height: 30px;
  }
  .icons{
    gap: 5px;
  }
  .icon-group{
    font-size: 5px;
  }
  .custom-icon{
    height: 40%;
  }
  .en-icon-group img{
    height: 50%;
  }
  .searching-bar{
    width: 300px;
    height:33px;
  }
  .search-button span {
   font-size: 8px;
  }
  .searching-bar button{
    padding: 5px;
  }
}
