.privacy-policy-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    text-decoration: none;
    color: #FF4D00;
  }
  
  h2 {
    color:#FF4D00;
    margin-top: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
  }
  
  p.pri {
    color: #666;
    line-height: 1.6;
    text-decoration: none;
  }
  
  ul {
    list-style: disc;
    margin-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  